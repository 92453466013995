import React, { useState, useRef } from "react";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Alert,
  Snackbar,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ResetPassword = ({ togglePassword }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [sent, setSent] = useState(false);
  const [message, setMessage] = useState("");

  const [error, setError] = useState(false);
  const [errorState, setErrorState] = useState({
    text: "There was an error!",
    state: "error",
  });

  function newError(error) {
    setErrorState({ text: error, state: "error" });
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 4500);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !validEmail(email)) {
      setErrorState({ text: "You must input a valid email!", state: "error" });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4500);
      return;
    }
    setButtonDisabled(true);
    setLoading(true);

    var formattedData = {
      email: email,
    };

    formattedData = JSON.stringify(formattedData);

    fetch("/api/login/resetpassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: formattedData,
    })
      .then((response) => {
        setButtonDisabled(false);
        setLoading(false);
        if (!response.ok) {
          newError("You got ratelimited, mostlikely");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (!data) {
          newError("Data wasn't sent");
          return;
        }
        if (data.err) {
          newError(data.message ? data.message : "There was an error");
          return;
        }
        if (data.message) {
          setMessage(data.message);
          setSent(true);
        }
      })
      .catch((err) => {
        setButtonDisabled(false);
        setLoading(false);
        newError("There was an error");
        console.error(err);
      });
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  return (
    <>
      <div className="login-container">
        <div className="login">
          {!sent ? (
            <>
              <h1 className="welcome-text">&nbsp;Reset Password&nbsp;</h1>
              <TextField
                className="inputBox"
                label="Email"
                value={email}
                onKeyDown={(e) => onKeyDown(e)}
                onChange={(e) => setEmail(e.target.value)}
                required={true}
              />
              <br />
              <br />
              <LoadingButton
                className="inputBox"
                color="success"
                type="submit"
                variant="contained"
                size="large"
                onClick={handleSubmit}
                loading={loading}
              >
                Reset Password!
              </LoadingButton>
              <br />
              <br />
              <Button disabled={isButtonDisabled} onClick={togglePassword}>
                Sign in!
              </Button>
            </>
          ) : (
            <div className="reset-password-text">{message}</div>
          )}
        </div>
      </div>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className="alert"
      >
        <Alert
          onClose={handleClose}
          severity={errorState.state}
          sx={{ width: "100%" }}
        >
          {errorState.text}
        </Alert>
      </Snackbar>
    </>
  );
};

const Login = ({ toggleComponent, togglePassword }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [error, setError] = useState(false);
  const [errorState, setErrorState] = useState({
    text: "There was an error!",
    state: "error",
  });

  const passwordFieldRef = useRef(null);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username || !password) {
      setErrorState({
        text: "Username and password required!",
        state: "error",
      });
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 4500);
      return;
    }
    setButtonDisabled(true);
    setLoading(true);
    var formattedData = {
      username: username,
      password: password,
    };

    formattedData = JSON.stringify(formattedData);

    setButtonDisabled(true);
    setLoading(true);
    fetch("/api/login/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: formattedData,
    })
      .then((response) => {
        setButtonDisabled(false);
        setLoading(false);
        if (!response.ok) {
          newError("You got ratelimited, mostlikely");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (!data) {
          newError("Name is too long");
          return;
        }
        if (data.err) {
          newError(data.message ? data.message : "There was an error");
          return;
        }
        if (data.redirect) {
          window.top.location.href = data.redirect;
        }
      })
      .catch((err) => {
        setButtonDisabled(false);
        setLoading(false);
        newError("There was an error");
        console.error(err);
      });
  };

  function newError(error) {
    setErrorState({ text: error, state: "error" });
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 4500);
  }

  const onKeyDown = (event, login) => {
    if (event.keyCode === 13) {
      if (login) {
        handleSubmit(event);
        return;
      }
      passwordFieldRef.current.focus();
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  return (
    <>
      <div className="login-container">
        <div className="login">
          <h1 className="welcome-text">Welcome Back!</h1>
          <TextField
            type="text"
            className="inputBox"
            label="Username"
            value={username}
            onKeyDown={(e) => onKeyDown(e, false)}
            onChange={(e) => setUsername(e.target.value)}
            required={true}
          />
          <br></br>
          <br></br>
          <TextField
            type={showPassword ? "text" : "password"}
            className="inputBox"
            label="Password"
            value={password}
            onKeyDown={(e) => onKeyDown(e, true)}
            onChange={(e) => setPassword(e.target.value)}
            inputRef={passwordFieldRef}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={togglePasswordVisibility}>
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required={true}
          />
          <br></br>
          <Button
            disabled={isButtonDisabled}
            className="align-left"
            onClick={togglePassword}
          >
            Forgot password?
          </Button>
          <br></br>
          <br></br>
          <LoadingButton
            className="inputBox"
            color="success"
            type="submit"
            variant="contained"
            size="large"
            onClick={handleSubmit}
            loading={loading}
          >
            Login
          </LoadingButton>
          <Snackbar
            open={error}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            className="alert"
          >
            <Alert
              onClose={handleClose}
              severity={errorState.state}
              sx={{ width: "100%" }}
            >
              {errorState.text}
            </Alert>
          </Snackbar>
          <br></br>
          <br></br>
          <Button disabled={isButtonDisabled} onClick={toggleComponent}>
            Don't have an account?&nbsp;
            <div style={{ color: "#000000" }}>
              <b>Sign up</b>
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

function validEmail(email) {
  const regex = new RegExp(
    `^(?=.{1,256}$)(?=.{1,64}@.{1,255}$)^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$`
  );
  return regex.test(email);
}

const Register = ({ toggleComponent }) => {
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [isparent, setIsParent] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [signedUp, setSignedUp] = useState(false);

  const [error, setError] = useState(false);
  const [errorState, setErrorState] = useState({
    text: "There was an error!",
    state: "error",
  });
  const [loading, setLoading] = useState(false);
  const nameRef = useRef(null);
  const lastnameRef = useRef(null);
  const dateRef = useRef(null);
  const usernameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const submitRef = useRef(null);

  const [showPassword, setShowPassword] = useState(false);

  function newError(error) {
    setErrorState({ text: error, state: "error" });
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 4500);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = () => {
    if (terms !== true){
      newError("Please agree to the terms of service");
      return;
    }
    if (password !== confirmPassword) {
      newError("Password's don't match!");
      return;
    }
    if (password.length < 8) {
      newError("Password is too short!");
      return;
    }
    if (password.length > 64) {
      newError("Password is too long!");
      return;
    }
    if (password.includes(" ")) {
      newError("No spaces allowed in password!");
      return;
    }
    if (password === null) {
      newError("Password required!");
      return;
    }
    if (!date || !date.isValid()) {
      newError("Invalid date!");
      return;
    }
    if (email == null) {
      newError("Email required!");
      return;
    }
    if (!validEmail(email)) {
      newError("Invalid email!");
      return;
    }
    if (
      username == null ||
      username.length <= 1 ||
      name == null ||
      name.length <= 1 ||
      lastname == null ||
      lastname.length <= 1
    ) {
      newError("Please fill in every box!");
      return;
    }
    if (username.length > 32) {
      newError("Username is too long");
      return;
    }
    if (lastname.length > 32) {
      newError("Lastname is too long");
      return;
    }
    if (name.length > 32) {
      newError("Name is too long");
      return;
    }
    if(isparent !== true && isparent !== false){
      newError("ERROR try reloading and trying again");
      return;
    }

    const DOB = `${date.$M <= 8 ? "0" + (date.$M + 1) : date.$M + 1}/${
      date.$D
    }/${date.$y}`;

    var formattedData = {
      username: username,
      password: password,
      email: email,
      DOB: DOB,
      name: name,
      lastname: lastname,
      isparent: isparent
    };

    formattedData = JSON.stringify(formattedData);

    setButtonDisabled(true);
    setLoading(true);
    fetch("/api/login/createaccount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: formattedData,
    })
      .then((response) => {
        setButtonDisabled(false);
        setLoading(false);
        if (!response.ok) {
          newError("Too many requests, please try again later");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (!data) {
          newError("Name is too long");
          return;
        }
        if (data.err) {
          newError(data.message ? data.message : "There was an error");
          return;
        }
        if (data.redirect) {
          window.top.location.href = data.redirect;
        }
        if (data.message) {
          setMessage(data.message);
          setSignedUp(true);
        }
      })
      .catch((err) => {
        setButtonDisabled(false);
        setLoading(false);
        newError("There was an error");
        console.error(err);
      });
  };

  const onKeyDown = (event, num) => {
    if (event.keyCode === 13) {
      switch (num) {
        case 0:
          lastnameRef.current.focus();
          break;
        case 1:
          dateRef.current.focus();
          break;
        case 2:
          usernameRef.current.focus();
          break;
        case 3:
          emailRef.current.focus();
          break;
        case 4:
          passwordRef.current.focus();
          break;
        case 5:
          confirmPasswordRef.current.focus();
          break;
        case 6:
          handleSubmit();
          break;
        default:
          break;
      }
    }
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const containerStyle = {
    display: "flex", // Use Flexbox
    alignItems: "center", // Align vertically in the center
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
  };

  return (
    <>
      <div>
        <div className="login-container">
          <div className="login">
            {!signedUp ? (
              <>
              <div className="register">
                <h1 className="welcome-text">Register For an Account!</h1>
                <div style={containerStyle}>
                  <TextField
                    className="inputBox name"
                    label="First Name"
                    value={name}
                    inputRef={nameRef}
                    onKeyDown={(e) => onKeyDown(e, 0)}
                    onChange={(e) => setName(e.target.value)}
                    required={true}
                  />
                  <TextField
                    className="inputBox lastname"
                    label="Last Name"
                    value={lastname}
                    inputRef={lastnameRef}
                    onKeyDown={(e) => onKeyDown(e, 1)}
                    onChange={(e) => setLastname(e.target.value)}
                    required={true}
                  />
                </div>
                <br></br>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="inputBox Birthday"
                    label="Birthday"
                    value={date}
                    required={true}
                    inputRef={dateRef}
                    onKeyDown={(e) => onKeyDown(e, 2)}
                    onChange={handleDateChange}
                  />
                </LocalizationProvider>
                <br></br>
                <br></br>
                <TextField
                  className="inputBox Username"
                  label="Username"
                  value={username}
                  inputRef={usernameRef}
                  onKeyDown={(e) => onKeyDown(e, 3)}
                  onChange={(e) => setUsername(e.target.value)}
                  required={true}
                />
                <br></br>
                <br></br>
                <TextField
                  className="inputBox Email"
                  label="Email"
                  value={email}
                  inputRef={emailRef}
                  onKeyDown={(e) => onKeyDown(e, 4)}
                  onChange={(e) => setEmail(e.target.value)}
                  required={true}
                />
                <br></br>
                <br></br>
                <TextField
                  type={showPassword ? "text" : "password"}
                  className="inputBox Password"
                  label="Password"
                  minLength="8"
                  inputRef={passwordRef}
                  onKeyDown={(e) => onKeyDown(e, 5)}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required={true}
                />
                <br></br>
                <br></br>
                <TextField
                  type={showPassword ? "text" : "password"}
                  className="inputBox ConfirmPassword"
                  minLength="8"
                  label="Confirm Password"
                  value={confirmPassword}
                  inputRef={confirmPasswordRef}
                  onKeyDown={(e) => onKeyDown(e, 6)}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required={true}
                />
                <br /><br />
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={terms} onChange={() => {setTerms(!terms)}} />} label={<div>I agree to the <a target="_blank" href="/terms">terms of service</a><span style={{color: "red"}}>*</span></div>} />
                  <FormControlLabel control={<Checkbox checked={isparent} onChange={() => {setIsParent(!isparent)}}/>} label={<div>Are you a parent?</div>} />
                </FormGroup>
                <br />
                <LoadingButton
                  className="inputBox"
                  color="success"
                  type="submit"
                  variant="contained"
                  size="large"
                  id="submit"
                  onClick={handleSubmit}
                  loading={loading}
                  inputRef={submitRef}
                >
                  Create Account
                </LoadingButton>
                <Snackbar
                  open={error}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  className="alert"
                >
                  <Alert
                    onClose={handleClose}
                    severity={errorState.state}
                    sx={{ width: "100%" }}
                  >
                    {errorState.text}
                  </Alert>
                </Snackbar>
                <br></br>
                <br></br>
                <Button disabled={isButtonDisabled} onClick={toggleComponent}>
                  Have an account?&nbsp;
                  <div style={{ color: "#000000" }}>
                    <b>Login!</b>
                  </div>
                </Button>
                </div>
              </>
            ) : (
              <div className="reset-password-text">{message}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const LoginPage = () => {
  const [login, setLogin] = useState(true);
  const [reset, setReset] = useState(false);

  const toggleComponent = () => {
    setLogin(!login);
  };

  const togglePassword = () => {
    console.log(reset);
    setLogin(reset);
    setReset(!reset);
  };

  return (
    <>
      <div>
        <img className="login-logo" alt="Logo" src="/images/Logo.png"></img>
        {login ? (
          <Login
            toggleComponent={() => toggleComponent()}
            togglePassword={() => togglePassword()}
          />
        ) : reset ? (
          <ResetPassword togglePassword={() => togglePassword()} />
        ) : (
          <Register toggleComponent={() => toggleComponent()} />
        )}
      </div>
    </>
  );
};

export default LoginPage;
